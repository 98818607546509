@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply bg-customColor;
    }
  }

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5%);
    }
  }
  
  .animate-bounce {
    animation: bounce 15s infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  .animate-pulse {
    animation: pulse 15s infinite;
  }
  